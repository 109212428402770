/// <reference types="vite/client" />

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// css imports
// import.meta.glob('./application.css', { eager: true })
import('./application.css')
import.meta.glob('../../components/**/*.css', { eager: true })
import.meta.glob('../../inputs/**/*.css', { eager: true })

import { Turbo } from "@hotwired/turbo-rails"
import "flowbite/dist/flowbite.turbo.js"
import "@/channels/index"
import "@/controllers/index"
import "@/lib/index"
import "@/stream_actions/index"

Turbo.start();

// Be noisey & let us know if a frame is missing
// Turbo will throw a warning, then try to load do a full page load instead
// But the error is getting swallowed on the page load and it is easy to miss
// Uncomment to debug (or set breakpoint)
// addEventListener("turbo:frame-missing", (event) => {
//   alert("Missing turbo:frame")
// });
