// Import and register all your controllers

import { application } from "./application"
import { registerControllers } from 'stimulus-vite-helpers'

async function importAll() {
  const controllers: Record<string, any> = import.meta.glob('@/controllers/**/*_controller.ts')
  // For each of our controllers found in the above glob
  // ensure the controller import is loaded and then register it
  for (const [name, controllerModule] of Object.entries(controllers)) {
    controllers[name] = await controllerModule();
  }
  registerControllers(application, controllers);

  const view_component_controllers_regex = /^.*\/components\/([^\/]+)_component\/\1_component_controller\.ts$/;
  const view_component_controllers: Record<string, any> = import.meta.glob('@view_components/**/*_controller.ts')

  for (const [name, controllerModule] of Object.entries(view_component_controllers)) {
    const match = name.match(view_component_controllers_regex);
    if (match && match[1]) {
      const result = `${match[1]}_controller.ts`;
      view_component_controllers[result] = await controllerModule();
    } else {
      console.error("No match for controller: ", name)
    }
  }
  registerControllers(application, view_component_controllers);

  const input_controllers_regex = /^.*\/inputs\/([^\/]+)_input\/\1_input_controller\.ts$/;
  const input_controllers: Record<string, any> = {};
  for (const [name, controllerModule] of Object.entries(import.meta.glob('@inputs/**/*_controller.ts'))) {
    const match = name.match(input_controllers_regex);
    if (match && match[1]) {
      const result = `${match[1]}_input_controller.ts`;
      input_controllers[result] = await controllerModule();
    } else {
      console.error("No match for controller: ", name)
    }
  }
  registerControllers(application, input_controllers);
}
importAll();
