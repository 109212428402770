// Import timezone handling
import("./timezone").then((timezone) => { timezone.setTimezoneCookie(); });

// Import Turbo monkeypatch
import("./turbo_monkeypatch").then((patch) => { patch.addTurboFramePatch(); });

// Import Axios Defaults
import("./axiosSetup").then((axiosSetup) => { axiosSetup.setup(); });

// Import theme function
import('./themeSetup').then((theme) => { theme.setupTheme(); });

// Import Flowbite DOM Observer
import('./flowbiteDomObserver').then((flowbiteDomObserver) => { flowbiteDomObserver.addFlowbiteDomObserver(); });
